<template>
  <div>
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          รายละเอียดแบนเนอร์
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3">
      <b-row>
        <b-col md="6">
          <InputText
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            v-model="form.name"
            isRequired
            :isValidate="$v.form.name.$error"
            :v="$v.form.name"
            @onKeyup="handleBannerDetail"
          />
        </b-col>
      </b-row>
    </div>
    <div class="bg-white p-3 mt-3">
      <b-row class="no-gutters mb-3">
        <div class="d-flex justify-content-between w-100">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหา"
              v-model="filter.Search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <b-button @click="createdBanner()" class="btn-main">สร้าง</b-button>
        </div>
      </b-row>
      <b-row class="no-gutters">
        <b-col>
          <b-table
            striped
            responsive
            hover
            :items="form.details"
            :fields="fields"
            :busy="isBusy"
            show-empty
            empty-text="ไม่พบข้อมูล"
            class="table-list"
          >
            <template v-slot:cell(imageUrl)="data">
              <div class="position-relative">
                <div
                  class="square-box b-contain"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imageUrl + ')',
                  }"
                ></div>
              </div>
            </template>
            <template v-slot:cell(updatedTime)="data">
              <span class="text-nowrap">{{
                $moment(data.item.updatedTime).format($formatDateTimeFull)
              }}</span>
            </template>
            <template v-slot:cell(status)="data">
              <div v-if="data.item.status == true" class="text-success">
                ใช้งาน
              </div>
              <div v-else class="text-danger">ไม่ใช้งาน</div>
            </template>
            <template v-slot:cell(id)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="link"
                  @click="edit(data.index, data.item)"
                  class="text-dark px-1 py-0"
                >
                  แก้ไข
                </b-button>
                <b-button
                  variant="link"
                  class="text-dark px-1 py-0"
                  @click="openModalDelete(data.item, data.index)"
                >
                  ลบ
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="no-gutters px-3 px-sm-0">
        <b-col
          class="form-inline justify-content-center justify-content-sm-between"
        >
          <div class="d-sm-flex">
            <b-pagination
              v-model="filter.PageNo"
              :total-rows="rows"
              :per-page="filter.PerPage"
              class="m-md-0"
              @change="pagination"
              align="center"
            ></b-pagination>
          </div>
          <b-form-select
            class="select-page"
            v-model="filter.PerPage"
            @change="hanndleChangePerpage"
            :options="pageOptions"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row
        class="no-gutters px-3 px-sm-0 pb-3 mt-3"
        v-if="$v.form.details.$error"
      >
        <span class="text-error">กรุณาเพิ่มข้อมูล</span>
      </b-row>
    </div>

    <div class="bg-white mt-3">
      <b-row class="mt-3 mb-4">
        <b-col>
          <b-row class="px-3">
            <b-col class="d-flex justify-content-between align-items-center">
              <div class="d-sm-flex my-3 align-items-center">
                <label class="font-weight-bold main-label">
                  การใช้งาน
                  <span class="text-danger">*</span>
                </label>
                <div>
                  <b-form-checkbox
                    switch
                    class="radio-active ml-3"
                    size="lg"
                    v-model="form.status"
                  >
                    <span class="ml-2 main-label">
                      {{ form.status ? "เปิด" : "ปิด" }}
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <b-button class="button btn-cancel ml-2" @click="back()"
                  >ย้อนกลับ</b-button
                >
                <b-button class="btn-main ml-3" @click="checkForm()"
                  >บันทึก</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    ModalAlertConfirm,
  },
  data() {
    return {
      id: this.$route.params.id,
      isDisable: false,
      modalMessage: "",
      form: {},
      isBusy: false,
      requestDeleteBanner: {
        id: null,
      },
      duplicateArray: [],
      rows: 0,
      fields: [
        {
          key: "imageUrl",
          label: "ภาพประกอบ",
          class: "w-100px",
        },
        {
          key: "name",
          label: "ชื่อ",
          class: "w-200",
        },
        {
          key: "sortOrder",
          label: "ลำดับการแสดง",
          class: "w-200",
        },
        {
          key: "updatedTime",
          label: "วันที่อัพเดท",
          class: "w-100px",
        },
        // {
        //   key: "status",
        //   label: "สถานะ",
        //   class: "w-100px",
        // },
        {
          key: "id",
          label: "",
        },
      ],
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      details: { required },
    },
  },
  computed: {
    ...mapState({
      templateId: (state) => state.templateId,
      bannerDetail: (state) => state.bannerDetail,
    }),
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      this.$isLoading = false;
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/${this.id}`,
        null,
        this.$headersSetting,
        this.filter
      );

      if (data.result == 1) {
        this.form = data.detail;
        if (this.filter.PageNo == 1) {
          this.rows = this.form.detailCount;
        }
        this.form.typeId = 1; // fix banner
        this.$v.form.$reset();
      }
      if (this.id == 0) {
        this.form = this.bannerDetail;
        this.duplicateArray = this.bannerDetail.details;
      }
      this.$isLoading = true;
    },
    createdBanner() {
      this.$store.commit("setIndexBannerDetail", null);
      this.$router.push({
        path: `/setting-banner/details/${this.$route.params.id}/0`,
      });
    },
    edit(index, data) {
      if (this.id == 0) {
        let val =
          this.filter.PageNo == 1
            ? index
            : this.filter.PerPage * this.filter.PageNo + index;
        this.$store.commit("setIndexBannerDetail", val);
      } else {
        this.$store.commit("setIndexBannerDetail", null);
      }

      this.$router.push({
        path: `/setting-banner/details/${this.$route.params.id}/${data.id}`,
      });
    },
    filterData() {
      this.form.details = [];
      if (this.filter.Search) {
        for (const e of this.duplicateArray) {
          if (e.name.toLowerCase().includes(this.filter.Search)) {
            this.form.details.push(e);
          }
        }
      } else {
        this.form.details = this.duplicateArray;
      }
      this.rows = this.form.details.length;
    },
    filterPagination() {
      this.rows = this.duplicateArray.length;
      let offset =
        this.filter.PageNo > 0
          ? (this.filter.PageNo - 1) * this.filter.PerPage
          : 0;
      let limit = this.filter.PerPage + offset;
      let arr = [];
      for (let index = 0; index < this.duplicateArray.length; index++) {
        if (index >= offset && index < limit) {
          arr.push(this.duplicateArray[index]);
        }
      }
      this.form.details = arr;
    },
    handleBannerDetail() {
      this.$store.commit("setBannerDetail", this.form.name);
    },
    back() {
      if (this.templateId) {
        this.$router.push({
          path: `/setting-homepage/details/${this.templateId}`,
        });
      } else {
        this.$router.push({
          path: `/setting-banner`,
        });
      }
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      if (this.id == 0) {
        this.filterPagination();
      } else this.getDatas();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      if (this.id == 0) {
        this.filterPagination();
      } else this.getDatas();
    },
    handleSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        if (this.id == 0) {
          this.filterData();
        } else this.getDatas();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      if (this.id == 0) {
        this.filterData();
      } else this.getDatas();
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        if (document.querySelector(".input-custom.error")) {
          this.$nextTick(() => {
            let domRect = document
              .querySelector(".input-custom.error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          });
        }
        return;
      }
      for (const e of this.form.details) {
        for (let index = 0; index < e.translationList.length; index++) {
          if (index == 1) {
            e.alttag_en = e.translationList[index].altTag;
          } else {
            e.altTag = e.translationList[index].altTag;
          }
        }
      }
      await this.submit();
    },
    openModalDelete(value, index) {
      if (this.id == 0) {
        this.form.details.splice(index, 1);
        let obj = {
          flag: false,
          data: this.form.details,
        };
        this.$store.commit("setBannerDetail", obj);
      } else {
        this.requestDeleteBanner.id = value.id;
        this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
        this.$refs.ModalAlertConfirm.show();
      }
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/Template/Section/Detail/${this.requestDeleteBanner.id}`,
        null,
        this.$headersSetting,
        null
      );

      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.PageNo = 1;
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 1500);
        await this.getDatas();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/Save`,
        null,
        this.$headersSetting,
        this.form
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
          this.back();
        }, 1500);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .panel-input-serach {
  border: 1px solid #bcbcbc !important;
  max-width: 350px;
}

::v-deep .card {
  margin-bottom: 0 !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
</style>
